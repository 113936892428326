import React, { useEffect, useRef, useState, useContext } from "react";
import Link from 'next/link'

export const PagesMenu = ({
  newsletter, title
}) => {

  const page_links = (pages) => {
    const p = []
    for(const page of pages) {
      p.push(
        <Link href={`/pages${page.title ? ('/' + page.title) : ""}`}
          className={`pt-4 px-6 h-full
          ${title === page.title ? ' border-b-2 border-gray-900' : ' hover:bg-gray-100 hover:border-gray-100'}
          `}>
          {page.title[0].toUpperCase()+page.title.slice(1)}
        </Link>
      )
    }
    return p
  }

  return (
    <div className="w-full border-b relative flex items-center justify-center text-gray-600 text-sm h-12">
      <Link
        href="/"
        className={`pt-4 px-6 h-full
        ${
          !title
            ? " border-b-2 border-gray-900"
            : " hover:bg-gray-100 hover:border-gray-100"
        }
        `}
      >
        Home
      </Link>
      {newsletter.pages?.nodes
        ? page_links(newsletter.pages.nodes)
        : page_links(newsletter.pages)}
    </div>
  );
}

export default PagesMenu;
